import React, { useState } from "react";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import elderimage_1 from "../images/elder_7.jpg";
import more from "../images/icons/triangle_down_wide.png";
import less from "../images/icons/triangle_up_wide.png";

const Social = () => {
  const [toggleValue, setToggleValue] = useState(-1);

  const toggleHandler = (index: number) => {
    index === toggleValue ? setToggleValue(-1) : setToggleValue(index);
  };

  return (
    <Container fluid>
      <Row className="spacerTop-2">
        <Col sm={12}>
          <p className="content-title shadow-title ml-3 main-title">
            <FormattedMessage id="social.title" />
          </p>
        </Col>
      </Row>
      <Row className="spacerTop-1">
        <Col sm={4} className="align-middle">
          <Image
            src={elderimage_1}
            alt="elder"
            fluid
            className="center mt-5 edge-2"
          />
          <br />
        </Col>
        <Col sm={7} className="align-middle bd-l">
          <p className="description ml-2">
            <FormattedMessage id="social-1" />
          </p>
          <Accordion flush className="bg-accordion">
            <Accordion.Item eventKey="3">
              <Accordion.Header onClick={() => toggleHandler(3)}>
                {toggleValue !== 3 ? (
                  <>
                    <img src={more} alt="more" className="accordionIcon" />
                  </>
                ) : (
                  <>
                    <img src={less} alt="less" className="accordionIcon" />
                  </>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <p className="description ml-2">
                  <FormattedMessage id="social-2" />
                </p>
                <p className="description ml-2">
                  <FormattedMessage id="social-3" />
                </p>
                <p className="description ml-2">
                  <FormattedMessage id="social-4" />
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col xl={1}></Col>
      </Row>
    </Container>
  );
};

export default Social;
