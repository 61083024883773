export enum AppRoute {
  HomePage = "routes.home",
  YouthPage = "routes.youth",
  AgriculturePage = "routes.agriculture",
  SocialPage = "routes.social",
  SelfhelpPage = "routes.selfhelp",
  SchoolProjectPage = "routes.schoolproject",
  EthiopiaPage = "routes.ethiopia",
  AboutPage = "routes.about",
  ContactPage = "routes.contact",
  DonatePage = "routes.donate",
  DonatePageInternal = "routes.donateInternal",
  ImpressionsPage = "routes.impressions",
}

export const AppRouteTitles = new Map([
  [AppRoute.HomePage, "home.title"],
  [AppRoute.YouthPage, "youth.title"],
  [AppRoute.AgriculturePage, "agriculture.title"],
  [AppRoute.SocialPage, "social.title"],
  [AppRoute.SelfhelpPage, "selfhelp.title"],
  [AppRoute.SchoolProjectPage, "actualprojects.school"],
  [AppRoute.EthiopiaPage, "ethiopia.title"],
  [AppRoute.AboutPage, "about.title"],
  [AppRoute.ContactPage, "contact.title"],
  [AppRoute.DonatePage, "donate.title"],
  [AppRoute.DonatePageInternal, "donate.Internal"],
  [AppRoute.ImpressionsPage, "impression.title"],
]);
