import React, { useState } from "react";
import "../custom.css";
import "../index.css";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const Donate = () => {
  const [subject] = useState("Spende an Faeleg e.V.");

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col sm={12}>
          <p className="content-title shadow-title ml-3 main-title spacerTop-2">
            <FormattedMessage id="donation.title" />
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={2}></Col>
        <Col sm={8}>
          <Row className="mt-3 bd-bottom-2 mb-4 justify-content-center">
            <Col className="col-auto">
              <FormattedMessage id="donation.paypal" />
            </Col>
            <Col className="mb-5 col-auto">
              <form
                action="https://www.paypal.com/donate"
                method="post"
                target="_top"
              >
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="DVASYFEEQNCLG"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  src="https://www.paypal.com/en_DE/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <p className="donate-text">
                <FormattedMessage id="donatation.subject" />
              </p>
            </Col>
            <Col sm="auto">
              <p className="donate-text">{subject}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <p className="donate-text">
                <FormattedMessage id="donation.bank.details" />
              </p>
            </Col>
            <Col sm="auto">
              <p className="donate-text">
                <FormattedMessage id="donation.bank" />
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <p className="donate-text">
                <FormattedMessage id="iban" />
              </p>
            </Col>
            <Col sm="auto">
              <p className="donate-text">
                <FormattedMessage id="donation.iban" />
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <p className="donate-text">
                <FormattedMessage id="bic" />
              </p>
            </Col>
            <Col sm="auto">
              <p className="donate-text">
                <FormattedMessage id="donation.bic" />
              </p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="mt-3 taxable">
              <FormattedMessage id="donation.taxable" />
            </Col>
          </Row>
        </Col>
        <Col sm={2}></Col>
      </Row>
    </Container>
  );
};

export default Donate;
