import { LanguageStrings } from "./base-strings";

export const en: LanguageStrings = {
  /** Routes */
  "routes.home": "/",
  "routes.youth": "/youth",
  "routes.ethiopia": "/ethiopia",
  "routes.agriculture": "/agriculture",
  "routes.social": "/social",
  "routes.selfhelp": "/selfhelp",
  "routes.schoolproject": "/schoolproject",
  "routes.about": "/about",
  "routes.contact": "/contact",
  "routes.donate": "/donate",
  "routes.donateInternal": "/donate",
  "routes.impressions": "/impressions",

  /** Page content */
  "navbar.title": "Faeleg e.V.",
  "donate.title": "Donate",
  "donate.Internal": "You can support the campaign here.",
  "country.title": "Ethiopia",
  "button.close": "Close",
  "home.subtitle-1": "The association",
  "home.subtitle-2": "The objectives",
  more: "more...",
  less: "less...",
  actualProject: "Current project:",

  "home.title": "Home",
  "home-1": "Welcome to the Faeleg e.V.",
  "home-2": "Feel free to visit our website or get in contact with us.",
  "home-3":
    "Faeleg e.V. (spring) is a non-profit, registered association based in Freiburg im Breisgau. The aim of the association is to initiate and implement projects and measures to support the people in Ethiopia.",
  "home-4":
    "The association works hand in hand in direct cooperation with the Ethiopian partner association Meda Charitable Organization. The intention of the cooperation is to carry out or support projects for children and young people, as well as in the social and agricultural field. In addition, with the sister association directly on site help for self-help groups are organized or promoted.",
  "home-5":
    "Although the political and social situation in Ethiopia is difficult, the partner association Meda plays an important and quite central role in the implementation of the projects on site.",
  "home-6":
    "The goal is, among other aspects, the promotion of economic, social, cultural and political exchange between Ethiopia and Germany. As well as the promotion and support of cultural and political exchange in the German and German-African civil society to promote international understanding.",
  "home-7":
    "In order to achieve the goals of the association, various activities are carried out in Germany. In addition, the association is dependent on donations in order to operate successfully.",

  "youth.title": "Children and youth projects",
  "youth-1":
    "Ethiopia still has one of the highest illiteracy rates in Africa. About 84 percent of girls and 87 percent of boys attend elementary school, but only about 53 percent complete it. Often, this is due to minor problems such as lack of exercise books, pencils, textbooks or financial resources.",
  "youth-2":
    "In the cities, the problems are different from those outside. Whereas in the countryside the endlessly long distances to school represent an almost insurmountable obstacle for many, in the city it is usually family constraints. Girls and young women are particularly badly affected. Their encouragement is therefore one of the focal points of our activities.",
  "youth-3":
    "Projects are planned in both rural and urban areas to enable children and young people to complete at least primary school.",
  "youth-4":
    "The members of our partner association include several teachers who have direct experience with the causes and the problems that arise. They are significantly involved in the implementation of the projects on site. In addition, the responsible authorities are involved in the projects.",

  "social.title": "Social projects",
  "social-1":
    "Nationwide, 20,000 inhabitants have less than one doctor at their disposal. In the Afar region, there is only one hospital in an area with 1.1 million people. For old or disabled people without families, there is virtually no medical care. The approach of Faeleg e.V. is not only financial support, but also consulting projects with the local people, e.g. concerning everyday things like simple dental hygiene.",
  "social-2":
    "Illiteracy affects not only the younger generation, but also the majority of adults of all ages. In personal lessons directly on site, people are to be taught basic knowledge that will enable them to cope with at least the basic everyday requirements on their own.",
  "social-3":
    "In close, direct cooperation with the Ethiopian partner association Meda Charitable Organization, projects and measures are implemented that directly benefit the people affected. There are also plans to set up mobile counseling and drop-in centers for victims of domestic violence and for girls and women whose living conditions are shaped by the prevailing family structures.",
  "social-4":
    "The focus is not only on financial support for needy people, but also on medical assistance, directly on site. Made possible by financial means of Faeleg e.V..",

  "agriculture.title": "Agricultural projects",
  "agriculture-1":
    'Ethiopia is a strongly agricultural country. However, so-called "cash crops" intended for export are predominantly cultivated. In Ethiopia, these are mainly coffee and cereals.',
  "agriculture-2":
    "For the agricultural projects, the association has land at its disposal on which, in active projects, sustainable cultivation and rearing of e.g. forgotten cereal species such as the millet species Fonio are taught. The projects are based on a holistic approach, organized as a cooperative, which provides water and electricity to the members of the cooperative. The projects are structured in such a way that the idea of agroforestry and biodiversity also play a major role.",
  "agriculture-3":
    "The aim is to promote a return to traditional and much more effective ways of subsistence in order to at least reduce the local dependence on existing economic structures. Sustainability plays a central role in all projects.",
  "agriculture-4": " ",
  "agriculture-5": " ",

  "selfhelp.title": "Help for self-help",
  "self-help-1":
    "A central topic in the initiatives of Faeleg e.V. is helping people to help themselves. What is meant here is not only financial, but to a greater extent also material and active help on site. The way is to be paved for abilities and talents which only need a suitable framework in order to develop. In order to bridge the existing inequality, especially women are promoted. The same applies to people with physical disabilities and the elderly.",
  "self-help-2":
    "As with the other projects, the personal relationship with the local people affected by the partner association forms the basis for the project work.",
  "self-help-3":
    "Important prerequisite for the implementation of a project is the personal contact and a project worthy of support in the sense of the association idea of Faeleg e.V..",
  "self-help-4":
    "The determining factor for the support granted is not the immediate life situation, but the content of the project and proof of need.",

  "actualprojects.title": "Current Projects",
  "actualprojects-sub-1.title": "School project for children in need",
  "actualprojects.school": "School project",
  "actual-projects-1":
    "A current project is dedicated to the provision of school supplies. Needy families are to receive basic equipment for their children's school attendance twice a year. Directly on site, the possibilities to verify the neediness and the necessary steps to distribute the necessary materials for school lessons are currently being determined with the partner association.",
  "actual-projects-2":
    "The following is required as basic equipment for school participation:",
  "actual-projects-3": "ballpoint pen",
  "actual-projects-4": "2 exercise books with 50 sheets",
  "actual-projects-5": "1 school bag",
  "actual-projects-6": "1 compasses",
  "actual-projects-costs-1":
    "The estimated cost of materials is 50 - 60€ per pupil (total approx. 6000€). In addition, there are transport costs in Germany of about 200€ and an expense allowance for 2 volunteers of 20€ each.",
  "actual-projects-costs-2":
    "The materials needed are bought locally in Ethiopia.",
  "actual-projects-costs-3": "You can support the campaign here.",

  "about.title": "About",
  "about.header": "The Faeleg e.V. Association",
  "about.description":
    "Faeleg e.V. is an association recognised as a non-profit organisation and entered in the register of associations.",
  "about.location": "Location of the association:",
  "about.co": "c/o Haus des Engagements",
  "about.zip": "79100",
  "about.city": "Freiburg",
  "about.street": "Rehlingstraße 9 Innenhof",
  "about.head": "Association Board:",
  "about.head-name": "Tamrat Goethe",
  "about.certificate":
    "Official approval certificate of the partner organisation - Meda.",
  "about.desc_cert":
    "The Ethiopian partner association, the Meda Charitable Organization, is also an officially certified non-profit organisation.",

  "contact.title": "Contact",
  "contact.firstname": "First name",
  "contact.familyname": "Family name",
  "contact.subject": "Subject",
  "contact.street": "Street",
  "contact.number": "Housnumber",
  "contact.email": "E-Mail",
  "contact.phone": "Phone",
  "contact.note": "Your note",
  "contact.choose": "Choose...",
  "contact.send": "Send",
  "contact.reset": "Reset",
  "contact.emailText":
    "We will not pass on e-mail addresses to third parties under any circumstances.",
  "contact.textarea": "Your note",

  "donation.title": "Your donation for Fealeg e.V.",
  "donation.paypal": "Via PayPal:",
  "donation.bank.details": "Bank details:",
  "donation.bank": "Deutsche Skatbank",
  iban: "IBAN:",
  bic: "BIC (SWIFT-Code):",
  "donation.iban": "DE92 8306 5408 0005 2731 53",
  "donation.bic": "GENO DEF1 SLR",
  "donatation.subject": "Purpose of use:",
  "donation.taxable":
    "Donations are tax deductible in Germany. For annual donations of at least 20 euros, you will automatically receive an annual donation receipt in February of the following year.",

  "ethiopia.title": "Ethiopia",
  "ethiopia.map.title": "Federal Democratic Republic of Ethiopia",
  "ethiopia.focus": "Click to see the regional focus of the projects.",
  "ethiopia.focus.title": "Regional focus of the projects",
  "ethiopia.language": "Official language",
  "ethiopia.amharic": "Amharic",
  "ethiopia.capitol": "Capital",
  "ethiopia.adisabeba": "Adis Ababa",
  "ethiopia.government": "State and government",
  "ethiopia.stateform": "parliamentary republic",
  "ethiopia.headofstate": "Head of state",
  "ethiopia.president": "President Sahle-Work Zewde",
  "ethiopia.headofgovernment": "Head of government",
  "ethiopia.head": "Prime Minister Abiy Ahmed Ali",
  "ethiopia.area": "Area",
  "ethiopia.square": "1.104.300 km² (2017)",
  "ethiopia.people": "Population",
  "ethiopia.peoplenumber": "112.079.000 (2019)",
  "ethiopia.populationdensity": "Population density",
  "ethiopia.density": "95 inhabitants per km²",
  "ethiopia.indexofhumandevelopment": "Human Development Index",
  "ethiopia.index": "0,485 (173.) (2019)",
  "ethiopia.founded": "Founded",
  "ethiopia.year": "1995",
  "ethiopia.nationalholiday": "National Day",
  "ethiopia.date": "28 May",

  "impression.title": "Impressions",
  "impression.header": "Impressions",

  "error.title": "Error",
  "error.contentParagraph": "The path you entered does not exist",
};
