import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { AppRoute, AppRouteTitles } from "./../const";
import NavDropdown from "react-bootstrap/NavDropdown";

interface Props {
  to: keyof typeof AppRoute;
  className?: string;
  children?: any;
  eventKey: string;
}

const NavDropdownItem: FC<Props> = ({ to, children, className, eventKey }) => {
  const { formatMessage, locale } = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}${formatMessage({ id: path })}`;
  }

  return (
    <NavDropdown.Item
      as={Link}
      to={localizeRouteKey(AppRoute[to])}
      className={className}
      eventKey={eventKey}
    >
      {typeof children !== "undefined"
        ? children
        : formatMessage({ id: AppRouteTitles.get(AppRoute[to]) || "" })}
    </NavDropdown.Item>
  );
};

export default NavDropdownItem;
