import "../custom.css";
import "../index.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FormattedMessage } from "react-intl";

/* Sending mail has to be implemented */

const Contact = () => {
  return (
    <Container fluid="md" className="content-center contact-width">
      <Row className="mt-3 bd-bottom-2">
        <Col className="content-title shadow-title ml-3 main-title">
          <FormattedMessage id="contact.title" />
        </Col>
      </Row>
      <Row>
        <Row>
          <Col sm={2}></Col>
          <Col sm={8}>
            <Form className="bg-contact">
              <Row>
                <Col xs={4}>
                  <Form.Label className="form-label">
                    <FormattedMessage id="contact.firstname" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="firstname"
                  />
                </Col>

                <Col xs={8}>
                  <Form.Label className="form-label">
                    <FormattedMessage id="contact.familyname" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="familyname"
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={8}>
                  <Form.Label htmlFor="inputEmail4" className="form-label">
                    <FormattedMessage id="contact.email" />
                  </Form.Label>
                  <Form.Control type="email" className="form-control" />
                  <Form.Text className="text-muted">
                    <FormattedMessage id="contact.emailText" />
                  </Form.Text>
                </Col>

                <Col xs={4}>
                  <Form.Label htmlFor="inputPhone" className="form-label">
                    <FormattedMessage id="contact.phone" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="inputPhone"
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label htmlFor="inputSubject" className="form-label">
                    <FormattedMessage id="contact.subject" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    id="inputSubject"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label htmlFor="inputNote" className="form-label">
                    <FormattedMessage id="contact.note" />
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    cols={80}
                    className="comment"
                    id="inputNote"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={3}>
                  <Button type="submit" className="btn btn-primary">
                    <FormattedMessage id="contact.send" />
                  </Button>
                </Col>
                <Col>
                  <Button type="reset" className="btn btn-primary">
                    <FormattedMessage id="contact.reset" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Contact;
