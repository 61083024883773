import { FC } from "react";
import { useIntl } from "react-intl";
import { AppRoute, AppRouteTitles } from "./../../../const";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

interface Props {
  to: keyof typeof AppRoute;
  className?: string;
  children?: React.ReactNode;
  eventKey?: string;
}

const NavigationLink: FC<Props> = ({ to, children, className, eventKey }) => {
  const { formatMessage, locale } = useIntl();

  function localizeRouteKey(path: string) {
    return `/${locale}${formatMessage({ id: path })}`;
  }

  return (
    <Nav.Link
      key={to}
      as={Link}
      to={localizeRouteKey(AppRoute[to])}
      className={className}
      eventKey={eventKey}
    >
      {typeof children !== "undefined"
        ? children
        : formatMessage({ id: AppRouteTitles.get(AppRoute[to]) || "" })}
    </Nav.Link>
  );
};

export default NavigationLink;
