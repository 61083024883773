import React, { useState } from "react";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import map from "../images/map/map.jpg";
import regionalMap from "../images/map/regionalMap_1.jpg";

function EthiopiaModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="fs-4 fw-bold lh-1"
        >
          <FormattedMessage id="ethiopia.focus.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={regionalMap} alt="Map" fluid />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>
          <FormattedMessage id="button.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Ethiopia = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <p className="content-title shadow-title ml-3 main-title spacerTop-1">
            <FormattedMessage id="ethiopia.map.title" />
          </p>
        </Col>
      </Row>
      <Row className="spacerBottom-1">
        <Row xs={12}>
          <Col lg={3}></Col>
          <Col lg={6} className="mx-auto">
            <div style={{ display: "block" }}>
              <OverlayTrigger
                key="right"
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-right`}>
                    <FormattedMessage id="ethiopia.focus" />
                  </Tooltip>
                }
              >
                <Image
                  src={map}
                  alt="ethiopian map"
                  fluid
                  className="p-2 mx-auto mapPointer mapSize"
                  onClick={() => setModalShow(true)}
                />
              </OverlayTrigger>
            </div>
            <EthiopiaModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Col>
          <Col lg={3}></Col>
        </Row>
        <Row>
          <Col lg={2}></Col>
          <Col lg={8}>
            <table className="table table-info table-hover">
              <tbody>
                <tr>
                  <td className="ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.language" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.amharic" />
                  </td>
                  <td className="border-start ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.capitol" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.adisabeba" />
                  </td>
                </tr>
                <tr>
                  <td className="ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.government" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.stateform" />
                  </td>
                  <td className="border-start ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.headofstate" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.president" />
                  </td>
                </tr>
                <tr>
                  <td className="ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.headofgovernment" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.head" />
                  </td>
                  <td className="border-start ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.area" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.square" />
                  </td>
                </tr>
                <tr>
                  <td className="ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.people" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.peoplenumber" />
                  </td>
                  <td className="border-start ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.populationdensity" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.density" />
                  </td>
                </tr>
                <tr>
                  <td className="ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.indexofhumandevelopment" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.index" />
                  </td>
                  <td className="border-start ethiopia-font-title col-w-40">
                    <FormattedMessage id="ethiopia.founded" />
                  </td>
                  <td className="ethiopia-font-content">
                    <FormattedMessage id="ethiopia.year" />
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Ethiopia;
