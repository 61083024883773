import React from "react";
import "../custom.css";
import "../index.css";
import "./Navigation.css";
import { Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import bgImage from "../images/bg_ethiopia.jpg";

const HeaderImage = () => {
  return (
    <div id="home" className="nav-container headerImage">
      <Image src={bgImage} fluid alt="landscape" />
      <div className="top-right nav-title">
        <FormattedMessage id="navbar.title" />
      </div>
    </div>
  );
};

export default HeaderImage;
