const baseStrings = {
  /** Routes */
  "routes.home": "/",
  "routes.youth": "/jugend",
  "routes.ethiopia": "/aethiopien",
  "routes.social": "/sozial",
  "routes.agriculture": "/landwirtschaft",
  "routes.selfhelp": "/selbsthilfe",
  "routes.schoolproject": "/schulprojekt",
  "routes.about": "/ueber",
  "routes.contact": "/kontakt",
  "routes.donate": "/spenden",
  "routes.donateInternal": "/spenden",
  "routes.impressions": "/impressionen",

  /** Page content */
  "navbar.title": "Faeleg e.V.",
  "donate.title": "Spenden",
  "donate.Internal": "Hier können Sie die Aktion unterstützen.",
  "country.title": "Äthiopien",
  "button.close": "Schließen",
  "home.subtitle-1": "Der Verein",
  "home.subtitle-2": "Die Ziele",
  more: "mehr...",
  less: "weniger...",
  actualProject: "Aktuelles Projekt:",

  "home.title": "Home",
  "home-1": "Willkommen bei Faeleg e.V.",
  "home-2":
    "Besuchen Sie die Website oder setzen Sie sich mit uns in Verbindung.",
  "home-3":
    "Faeleg e.V. (Quelle) ist ein gemeinnütziger, eingetragener Verein mit Sitz in Freiburg im Breisgau. Ziel des Vereins ist es Projekte und Maßnahmen zur Unterstützung der Menschen in Äthiopien zu initiieren und durchzuführen.",
  "home-4":
    "Der Verein arbeitet Hand in Hand in direkter Kooperation mit dem äthiopischen Partnerverein Meda Charitable Organization. Intention der Zusammenarbeit ist es Projekte für Kinder und Jugendliche, sowie im sozialen und landwirtschaftlichen Bereich durchzuführen oder zu unterstützen. Darüberhinaus werden mit dem Schwesterverein direkt vor Ort Hilfe zur Selbsthilfe Gruppen organisiert oder gefördert.",
  "home-5":
    "Obwohl die politische und gesellschaftliche Situation in Äthiopien schwierig ist, kommt dem Partnerverein Meda eine wichtige und durchaus zentrale Rolle bei der Durchführung der Projekte vor Ort zu.",
  "home-6":
    "Ziel ist unter anderem die Förderung des wirtschaftlichen, sozialen, kulturellen und politischen Austauschs zwischen Äthiopien und Deutschland. Sowie die Förderung und Unterstützung des kulturellen und politischen Austausches in der deutschen und deutsch-afrikanischen Zivilgesellschaft um die Völkerverständigung zu fördern.",
  "home-7":
    "Um die Ziele des Vereins erreichen zu können werden verschiedene Aktivitäten in Deutschland durchgeführt. Darüber hinaus ist der Verein, um erfolgreich tätig zu sein, auf Spenden angewiesen.",

  "youth.title": "Kinder- und Jugendprojekte",
  "youth-1":
    "Äthiopien hat auch heute noch eine der höchsten Analphabetismusraten in Afrika. Etwa 84 Prozent der Mädchen und 87 Prozent der Jungen besuchen zwar die Grundschule aber nur etwa 53 Prozent schließen diese auch ab. Häufig sind es nur Kleinigkeiten wie Schulhefte, Stifte, Schulbücher oder fehlende finanzielle Mittel die dazu führen.",
  "youth-2":
    "In den Städten sind die Probleme anders gelagert als außerhalb. Sind es auf dem Land bereits die endlos langen Schulwege die für viele ein kaum überwindbares Hindernis darstellen, sind es in der Stadt in der Regel familiäre Zwänge. Besonders hart betroffen sind vor allem Mädchen und junge Frauen. Ihre Förderung bildet daher einen Schwerpunkt unserer Tätigkeit.",
  "youth-3":
    "Es sind Projekte sowohl im ländlichen als auch im städtischen Bereich geplant, die Kindern und Jugendlichen zumindest den Abschluß der Grundschule ermöglichen sollen.",
  "youth-4":
    "Zu den Mitgliedern unseres Partnervereins gehören mehrere Lehrer die über unmittelbare Erfahrungen mit den Ursachen und den auftretenden Problem verfügen. Sie sind maßgeblich an der Umsetzung der Projekte vor Ort beteiligt. Darüberhinaus werden die zuständigen Behörden in die Projekte mit einbezogen.",

  "social.title": "Soziale Projekte",
  "social-1":
    "Landesweit stehen 20.000 Einwohnern weniger als ein Arzt zur Verfügung. In der Region Afar gibt es in einem Gebiet mit 1,1 Millionen Menschen nur ein einziges Hospital. Für alte oder behinderte Menschen ohne Familie gibt es so gut wie keine medizinische Versorgung. Ansatz ist hier für den Faeleg e.V. nicht nur die finanzielle Unterstützung sondern auch beratende Projekte mit den Menschen vor Ort, die z.B. alltägliche Dinge wie einfache Zahnhygiene betreffen.",
  "social-2":
    "Analphabetismus betrifft nicht nur die junge Generation auch die Mehrzahl der Erwachsenen aller Alterstufen gehört zu dieser Gruppe. In persönlichen Unterrichtseinheiten direkt vor Ort sollen den Menschen grundlegende Kenntnisse vermittelt werden, die sie in die Lage versetzen zumindest die grundlegenden alltäglichen Anforderungen selbstständig zu bewältigen.",
  "social-3":
    "In enger, direkter Zusammenarbeit mit dem äthiopischen Partnerverein Meda Charitable Organization werden Projekte und Maßnahmen durchgeführt die unmittelbar den betroffenen Menschen zugutekommen. Geplant ist auch der Aufbau von mobilen Beratungs- und Anlaufstellen für Opfer häuslicher Gewalt sowie Mädchen und Frauen deren Lebensumstände durch die vorherschenden familiären Strukturen geprägt sind.",
  "social-4":
    "Im Mittelpunkt steht nicht nur die finanzielle Unterstützung bedürftiger Menschen, sondern auch medizinische Hilfe, direkt vor Ort. Ermöglicht durch finanzielle Mittel von Faeleg e.V..",

  "agriculture.title": "Landwirtschaftliche Projekte",
  "agriculture-1":
    'Äthiopien ist stark landwirtschaftlich geprägt. Überwiegend werden aber sogenannte, für den Export bestimmte "Cash Crops" angebaut. In Äthiopien handelt es sich dabei vorwiegend um Kaffee und Getreide. Für die landwirtschaftlichen Projekte steht dem Verein Land zur Verfügung auf dem in aktiven Projekten nachhaltiger Anbau und die Aufzucht z.B. vergessener Getreide Arten wie die Hirseart Fonio vermittelt werden.',
  "agriculture-2":
    "Dabei wird ein ganzheitlicher Ansatz zugrunde gelegt, der genossenschaftlich organisiert den Mitgliedern der Genossenschaft Versorgung mit Wasser und Elektrizität gewährleistet. Die Projekte sind so aufgebaut, dass auch die Idee der Agroforstwirtschaft und Biodiversität einen breiten Raum einnehmen.",
  "agriculture-3":
    "Ziel ist es die Rückbesinnung auf traditionelle und für den Eigengebrauch wesentlich effektivere Arten zu fördern um so die lokale Abhängigkeit von den bestehenden wirtschaftlichen Strukturen zumindest abzumildern. Nachhaltigkeit spielt bei allen Projekten eine zentrale Rolle.",
  "agriculture-4": " ",
  "agriculture-5": " ",

  "selfhelp.title": "Hilfe zur Selbsthilfe",
  "self-help-1":
    "Ein zentrales Thema bei den Initiativen von Faeleg e.V. bildet die Hilfe zur Selbsthilfe. Gemeint ist hier nicht nur die finanzielle, sondern in größerem Umfang auch materielle und aktive Hilfe vor Ort. Fähigkeiten und Begabungen die nur einen geeigneten Rahmen benötigen um sich zu entfalten soll der Weg geebnet werden. Um die bestehende Ungleichkeit zu überbrücken werden insbesondere Frauen gefördert. Gleiches gilt für Menschen mit körperliche Behinderung und für ältere Menschen.",
  "self-help-2":
    "Wie auch bei den anderen Projekten bildet die durch den Partnerverein gegebene persönliche Beziehung zu den Betroffenen vor Ort die Basis für die Projektarbeit.",
  "self-help-3":
    "Wichtige Vorraussetzung für die Durchführung eines Projektes ist der persönliche Kontakt und ein, im Sinne der Vereinsidee von Faeleg e.V., förderungswüdiges Projekt.",
  "self-help-4":
    "Ausschlaggebend für die gewährte Unterstützung ist nicht die unmittelbare Lebenssituation, sondern Inhalt des Projekts und der Nachweis der Notwendigkeit.",

  "actualprojects.title": "Aktuelle Projekte",
  "actualprojects-sub-1.title": "Schulprojekt für bedürftige Kinder",
  "actualprojects.school": "Schulprojekt",
  "actual-projects-1":
    "Ein derzeit laufendes Projekt ist der Versorgung von Schüler*innen gewidmet. Bedürftige Familien sollen zweimal im Jahr eine Grundausstattung für den Schulbesuch ihrer Kinder erhalten. Direkt vor Ort werden derzeit mit dem Partnerverein die Möglichkeiten zur Überprüfung der Bedürftigkeit und die notwendigen Schritte zur Verteilung der für den Schulunterricht notwendigen Materialien festgelegt.",
  "actual-projects-2":
    "Als Grundausstattung für den Schulbesuch wird folgendes benötigt:",
  "actual-projects-3": "Kugelschreiber",
  "actual-projects-4": "2 Hefte mit 50 Blatt",
  "actual-projects-5": "1 Schulranzen",
  "actual-projects-6": "1 Zirkel",
  "actual-projects-costs-1":
    "Die veranschlagten Materialkosten betragen 50 - 60€ je Schüler (gesamt ca. 6000€). Dazu kommen Transportkosten im Inland ca. 200€ und eine Aufwandspauschale für 2 Ehrenamtliche Mitarbeiter von je 20€.",
  "actual-projects-costs-2":
    "Die benötigten Materialien werden vor Ort in Äthiopien gekauft.",
  "actual-projects-costs-3": "Hier können Sie die Aktion unterstützen.",

  "about.title": "Über",
  "about.header": "Der Verein Faeleg e.V.",
  "about.description":
    "Faeleg e.V. ist ein als gemeinnützig anerkannter Verein, mit Eintrag in das Vereinsregister.",
  "about.location": "Adresse des Vereins:",
  "about.co": "c/o Haus des Engagements",
  "about.zip": "79100",
  "about.city": "Freiburg",
  "about.street": "Rehlingstraße 9 Innenhof",
  "about.head": "Vereinsvorstand:",
  "about.head-name": "Tamrat Goethe",
  "about.certificate":
    "Offizielles Zulassungszertifikat der Partner Organisation - Meda",
  "about.desc_cert":
    "Der äthiopische Partnerverein, die Meda Charitable Organization ist ebenfalls eine offiziell zertifizierte, gemeinnützige Organisation.",

  "contact.title": "Kontakt",
  "contact.firstname": "Vorname",
  "contact.familyname": "Nachname",
  "contact.subject": "Betreff",
  "contact.street": "Straße",
  "contact.number": "Hausnummer",
  "contact.email": "E-Mail",
  "contact.phone": "Telefon",
  "contact.note": "Ihre Nachricht",
  "contact.choose": "Land wählen",
  "contact.send": "Senden",
  "contact.reset": "Löschen",
  "contact.emailText":
    "E-Mail Adressen werden von uns in keinem Fall an Dritte weitergegeben.",
  "contact.textarea": "Ihre Nachricht",

  "donation.title": "Ihre Spende für Faeleg e.V.",
  "donation.paypal": "Via PayPal:",
  "donation.bank.details": "Bankverbindung:",
  "donation.bank": "Deutsche Skatbank",
  iban: "IBAN:",
  bic: "BIC (SWIFT-Code):",
  "donation.iban": "DE92 8306 5408 0005 2731 53",
  "donation.bic": "GENO DEF1 SLR",
  "donatation.subject": "Verwendungszweck:",
  "donation.taxable":
    "Spenden sind in Deutschland steuerlich absetzbar. Ab einem Jahresspendenbetrag von mindestens 20 Euro erhalten Sie automatisch eine Jahresspendenquittung im Februar des nächsten Jahres.",

  "ethiopia.title": "Äthiopien",
  "ethiopia.map.title": "Föderale Demokratische Republik Äthiopien",
  "ethiopia.focus":
    "Klicken Sie um den regionalen Schwerpunkt der Projekte zu sehen.",
  "ethiopia.focus.title": "Regionaler Schwerpunkte der Projekte",
  "ethiopia.language": "Amtssprache",
  "ethiopia.amharic": "Amharisch",
  "ethiopia.capitol": "Hauptstadt",
  "ethiopia.adisabeba": "Adis Abeba",
  "ethiopia.government": "Staats- und Regierungsform",
  "ethiopia.stateform": "parlamentarische Republik",
  "ethiopia.headofstate": "Staatsoberhaupt",
  "ethiopia.president": "Präsidentin Sahle-Work Zewde",
  "ethiopia.headofgovernment": "Regierungschef",
  "ethiopia.head": "Ministerpräsident Abiy Ahmed Ali",
  "ethiopia.area": "Fläche",
  "ethiopia.square": "1.104.300 km² (2017)",
  "ethiopia.people": "Einwohnerzahl",
  "ethiopia.peoplenumber": "112.079.000 (2019)",
  "ethiopia.populationdensity": "Bevölkerungsdichte",
  "ethiopia.density": "95 Einwohner pro km²",
  "ethiopia.indexofhumandevelopment": "Index der menschlichen Entwicklung",
  "ethiopia.index": "0,485 (173.) (2019)",
  "ethiopia.founded": "Gegründet",
  "ethiopia.year": "1995",
  "ethiopia.nationalholiday": "Nationalfeiertag",
  "ethiopia.date": "28. Mai",

  "impression.title": "Impressionen",
  "impression.header": "Impressionen",

  "error.title": "Error",
  "error.contentParagraph": "Der von Ihnen eingegebene Pfad existiert nicht",
};

export type LanguageStrings = typeof baseStrings;
export const de = baseStrings;
