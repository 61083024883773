import React, { useState } from "react";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import logo from "../images/LogoFaeleg_400_A.png";
import more from "../images/icons/triangle_down_wide.png";
import less from "../images/icons/triangle_up_wide.png";

const Home = () => {
  const [toggleValue, setToggleValue] = useState(-1);

  const toggleHandler = (index: number) => {
    index === toggleValue ? setToggleValue(-1) : setToggleValue(index);
  };

  return (
    <Container fluid>
      {/* -------------------- home page ------------------------- */}

      <Row>
        <Col className="text-center spacerTop-1 spacerBottom-1">
          <p className="content-title shadow-title">
            <FormattedMessage id="home-1" />
          </p>
        </Col>
      </Row>
      <Row colSpan={4}>
        <Col className="align-middle">
          <Image src={logo} alt="Logo" fluid className="logo-position center" />
          <br></br>
        </Col>

        <Col xl={6} md={6} className="align-middle bd-l">
          <h4>
            <FormattedMessage id="home.subtitle-1" />
          </h4>
          <p className="description ml-2">
            <FormattedMessage id="home-3" />
          </p>
          <Accordion flush className="bg-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={() => toggleHandler(2)}>
                {toggleValue !== 2 ? (
                  <>
                    <img src={more} alt="more" className="accordionIcon" />
                  </>
                ) : (
                  <>
                    <img src={less} alt="less" className="accordionIcon" />
                  </>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <p className="description ml-2">
                  <FormattedMessage id="home-4" />
                </p>
                <p className="description ml-2">
                  <FormattedMessage id="home-5" />
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <h4 className="spacerTop-1">
            <FormattedMessage id="home.subtitle-2" />
          </h4>
          <p className="description ml-2">
            <FormattedMessage id="home-6" />
          </p>
          <p className="description">
            <FormattedMessage id="home-7" />
          </p>
        </Col>
        <Col xl={2}></Col>
      </Row>
      <Row>
        <Col xl={2}>&nbsp;</Col>
      </Row>
    </Container>
  );
};

export default Home;
