import { Button, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";

interface ImageModalProps {
  src: string;
  show: boolean;
  onHide: () => void;
}

export function ImageModal(props: ImageModalProps) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Image src={props.src} alt={props.src} fluid />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>
          <FormattedMessage id="button.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
