import React from "react";
import "./Navigation.css";
import "../index.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import NavigationLink from "../modules/i18n/components/NavigationLink";

const Footer = () => (
  <footer className="pt-4">
    <div className="container-fluid text-center">
      <div className="row">
        <Navbar bg="primary" variant="light" fixed="bottom">
          <Container fluid>
            <Nav className="me-auto FooterButtons mx-auto">
              <NavigationLink
                to="ContactPage"
                className="NavigationButtons spacer-right"
              />

              <NavigationLink
                to="AboutPage"
                className="NavigationButtons spacer-right"
              />

              <NavigationLink
                to="DonatePage"
                className="NavigationButtons spacer-right"
              />
            </Nav>
          </Container>
        </Navbar>
      </div>
    </div>
  </footer>
);

export default Footer;
