import React, { Component } from "react";
import { Route } from "react-router";
import "./App.css";
import Navigation from "./components/Navigation";

import { AppRoute } from "./const";
import { appStrings, LocalizedRouter, LocalizedRoutes } from "./modules/i18n";
import Footer from "./components/Footer";
import * as pages from "./pages";

class App extends Component {
  userLang = navigator.language;

  state = {
    data: null,
  };

  componentDidMount() {
    this.callBackendAPI()
      .then((res) => this.setState({ data: res.express }))
      .catch((err) => console.log(err));
  }

  //fetching the get route from the Express server
  callBackendAPI = async () => {
    const response = await fetch("/express_backend");
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  render() {
    return (
      <>
        <LocalizedRouter languages={this.userLang} appStrings={appStrings}>
          <Navigation />
          <LocalizedRoutes>
            <Route path={AppRoute.HomePage} element={<pages.HomePage />} />

            <Route path={AppRoute.YouthPage} element={<pages.YouthPage />} />
            <Route path={AppRoute.SocialPage} element={<pages.SocialPage />} />
            <Route
              path={AppRoute.AgriculturePage}
              element={<pages.AgriculturePage />}
            />
            <Route
              path={AppRoute.SelfhelpPage}
              element={<pages.SelfhelpPage />}
            />
            <Route
              path={AppRoute.SchoolProjectPage}
              element={<pages.SchoolProjectPage />}
            />
            <Route
              path={AppRoute.EthiopiaPage}
              element={<pages.EthiopiaPage />}
            />
            <Route
              path={AppRoute.ImpressionsPage}
              element={<pages.ImpressionsPage />}
            />

            <Route path={AppRoute.AboutPage} element={<pages.AboutPage />} />
            <Route
              path={AppRoute.ContactPage}
              element={<pages.ContactPage />}
            />
            <Route path={AppRoute.DonatePage} element={<pages.DonatePage />} />
            <Route path="*" element={<pages.ErrorPage />} />
          </LocalizedRoutes>
          <Footer />
        </LocalizedRouter>
        <p className="App-intro">{this.state.data}</p>
      </>
    );
  }
}

export default App;
