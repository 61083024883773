import React from "react";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";

const About = () => {
  return (
    <Container fluid="md" className="content-center about-width">
      <Row className="mt-3 spacerBottom-1 bd-bottom-2">
        <Col className="content-title shadow-title ml-3 main-title">
          <FormattedMessage id="about.header" />
        </Col>
      </Row>

      <Row className="spacerTop-1">
        <Col sm={2}></Col>
        <Col sm={8}>
          <Row>
            <Col>
              <h6 className="about-text">
                <FormattedMessage id="about.description" />
              </h6>
            </Col>
          </Row>
          <Row className="mt-3 bd-bottom-2">
            <Col>
              <span className="content-title-sm">
                <FormattedMessage id="about.location" />
              </span>
              <br />
              <p className="about-text">
                <FormattedMessage id="about.co" />
                <br />
                <FormattedMessage id="about.zip" />
                &nbsp;
                <FormattedMessage id="about.city" />
                <br />
                <FormattedMessage id="about.street" />
              </p>
            </Col>
          </Row>
          <Row className="mt-2 bd-bottom-2">
            <Col>
              <p>
                <span className="content-title-sm">
                  <FormattedMessage id="about.head" />
                </span>
                <br />
                <span className="about-text">
                  <FormattedMessage id="about.head-name" />
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p className="about-text">
                <FormattedMessage id="about.desc_cert" />
              </p>
            </Col>
          </Row>
        </Col>
        <Col sm={2}></Col>
      </Row>
    </Container>
  );
};

export default About;
