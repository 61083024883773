import React from "react";
import "../custom.css";
import "../index.css";
import "./Navigation.css";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import NavigationLink from "../modules/i18n/components/NavigationLink";
import NavDropdownItem from "./NavDropdownItem";
import { useLocation } from "react-router-dom";
import { LanguageSwitcher } from "../modules/i18n";
import HeaderImage from "./HeaderImage";

const Navigation = () => {
  const { pathname } = useLocation();
  const route = pathname.substring(1, 3);
  let projects = " Tätigkeitsfelder";
  let actualProjects = "Aktuelle Projekte";
  if (route === "de") {
    projects = " Tätigkeitsfelder";
    actualProjects = "Aktuelle Projekte";
  } else if (route === "en") {
    projects = "Field of activities";
    actualProjects = "Actual projets";
  }

  return (
    <div>
      <Navbar
        collapseOnSelect
        bg="primary"
        expand="lg"
        fixed="top"
        variant="light"
      >
        <Container fluid className="mt-0">
          <Navbar.Brand href="#">&nbsp;</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* Navigations links */}
            <Nav className="NavigationButtons me-auto">
              <NavigationLink
                to="HomePage"
                className="me-auto"
                eventKey="home"
              />
            </Nav>
            <Nav className="NavigationButtons">
              <NavDropdown title={projects} className="NavigationButtons">
                {/* Navigation for general projects overview */}
                <NavDropdownItem
                  to="YouthPage"
                  className="dropdown-item-font-size"
                  eventKey="2"
                />
                <NavDropdownItem
                  to="SocialPage"
                  className="dropdown-item-font-size"
                  eventKey="3"
                />
                <NavDropdownItem
                  to="AgriculturePage"
                  className="dropdown-item-font-size"
                  eventKey="4"
                />
                <NavDropdownItem
                  to="SelfhelpPage"
                  className="spacer-right dropdown-item-font-size"
                  eventKey="6"
                />
              </NavDropdown>
              {/* Navigation for actual projects */}
              <NavDropdown title={actualProjects} className="NavigationButtons">
                <NavDropdownItem
                  to="SchoolProjectPage"
                  className="spacer-right dropdown-item-font-size"
                  eventKey="7"
                />
              </NavDropdown>

              <NavigationLink
                to="EthiopiaPage"
                className="NavigationButtons spacer-right"
                eventKey="ethiopia"
              />
              <NavigationLink
                to="ImpressionsPage"
                className="NavigationButtons spacer-right"
              />
            </Nav>
            <div className="spacer-right"></div>
            {/* language switch */}
            <Nav className="LanguageSwitcher">
              <LanguageSwitcher />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <HeaderImage />
    </div>
  );
};

export default Navigation;
