import React, { useState } from "react";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col, Image, Accordion } from "react-bootstrap";
import NavigationLink from "../modules/i18n/components/NavigationLink";
import youthimage_5 from "../images/youth_7.jpg";
import more from "../images/icons/triangle_down_wide.png";
import less from "../images/icons/triangle_up_wide.png";

const Youth = () => {
  const [toggleValue, setToggleValue] = useState(-1);

  const toggleHandler = (index: number) => {
    index === toggleValue ? setToggleValue(-1) : setToggleValue(index);
  };

  return (
    <Container fluid>
      <Row className="spacerTop-2">
        <Col sm={12}>
          <p className="content-title shadow-title ml-3 main-title">
            <FormattedMessage id="youth.title" />
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className="align-middle">
          <Image
            src={youthimage_5}
            alt="Logo"
            fluid
            className="center mt-5 edge-2"
          />
          <br />
        </Col>
        <Col sm={7} className="align-middle bd-l">
          <p className="description ml-2">
            <FormattedMessage id="youth-1" />
            <br />
            <FormattedMessage id="youth-2" />
          </p>
          <Accordion flush className="bg-accordion">
            <Accordion.Item eventKey="2">
              <Accordion.Header onClick={() => toggleHandler(2)}>
                {toggleValue !== 2 ? (
                  <>
                    <img src={more} alt="more" className="accordionIcon" />
                  </>
                ) : (
                  <>
                    <img src={less} alt="less" className="accordionIcon" />
                  </>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <p className="description ml-2">
                  <FormattedMessage id="youth-3" />
                </p>
                <p className="description ml-2">
                  <FormattedMessage id="youth-4" />
                </p>
                <p></p>
                <p className="description ml-2">
                  <FormattedMessage id="actualProject" />
                  &nbsp;&nbsp;
                  <NavigationLink
                    to="SchoolProjectPage"
                    className=" internalLink"
                  />
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col xl={1}></Col>
      </Row>
    </Container>
  );
};

export default Youth;
