import React, { useState } from "react";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Row,
  Col,
  Image,
  Accordion,
  ListGroup,
} from "react-bootstrap";
import NavigationLink from "../modules/i18n/components/NavigationLink";
import school_project from "../images/school_1.jpg";
import more from "../images/icons/triangle_down_wide.png";
import less from "../images/icons/triangle_up_wide.png";

const SchoolProject = () => {
  const [toggleValue, setToggleValue] = useState(-1);

  const toggleHandler = (index: number) => {
    index === toggleValue ? setToggleValue(-1) : setToggleValue(index);
  };

  return (
    <Container fluid>
      <Row className="spacerTop-2">
        <Col sm={12}>
          <p className="content-title shadow-title ml-3 main-title">
            <FormattedMessage id="actualprojects.title" />
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={4} className="align-middle">
          <Image
            src={school_project}
            alt="schoolproject"
            fluid
            className="mt-5 edge-2"
          />
          <br />
        </Col>
        <Col sm={7} className="align-middle bd-l">
          <p className="content-sub-title shadow-title ml-3 spacerTop-2">
            <FormattedMessage id="actualprojects-sub-1.title" />
          </p>
          <p className="description ml-2">
            <FormattedMessage id="actual-projects-1" />
          </p>
          <p className="description ml-2">
            <FormattedMessage id="actual-projects-2" />
          </p>
          <Accordion flush className="bg-accordion">
            <Accordion.Item eventKey="5">
              <Accordion.Header onClick={() => toggleHandler(5)}>
                {toggleValue !== 5 ? (
                  <>
                    <img src={more} alt="more" className="accordionIcon" />
                  </>
                ) : (
                  <>
                    <img src={less} alt="less" className="accordionIcon" />
                  </>
                )}
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush" className="list-format">
                  <ListGroup.Item>
                    <FormattedMessage id="actual-projects-3" />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FormattedMessage id="actual-projects-4" />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FormattedMessage id="actual-projects-5" />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FormattedMessage id="actual-projects-6" />
                  </ListGroup.Item>
                </ListGroup>
                <br />
                <p className="description ml-2">
                  <FormattedMessage id="actual-projects-costs-1" />
                </p>
                <p className="description ml-2">
                  <FormattedMessage id="actual-projects-costs-2" />
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <p className="description ml-2">
            <NavigationLink to="DonatePageInternal" className="internalLink" />
          </p>
        </Col>
        <Col sm={1}></Col>
      </Row>
      <Row>
        <Col sm={1}>&nbsp;</Col>
      </Row>
    </Container>
  );
};

export default SchoolProject;
