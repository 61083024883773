import React from "react";
import { AppLanguages } from "./../../../const";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useIntl } from "react-intl";

import { appStrings } from "./../index";

export const LanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { messages } = useIntl();

  function getMatchingRoute(language: string) {
    /**
     * Get the key of the route the user is currently on
     */
    const route = pathname.substring(3); // remove local part '/en' from the pathname /en/contact
    const routeKey =
      Object.keys(messages).find((key) => messages[key] === route) ?? "Deutsch";
    /**
     * Find the matching route for the new language
     */
    const matchingRoute = (appStrings as any)[language][routeKey];

    /**
     * Return localized route
     */
    return `/${language}` + matchingRoute;
  }

  return (
    <>
      {Object.keys(AppLanguages).map((lang) => (
        <Nav.Link
          key={lang}
          as={Link}
          eventKey={(AppLanguages as any)[lang]}
          // className=""
          // activeClassName={css(link.active)}
          to={getMatchingRoute((AppLanguages as any)[lang])}
        >
          {(AppLanguages as any)[lang]}
        </Nav.Link>
      ))}
    </>
  );
};
