import React from "react";
import "../custom.css";
import "../index.css";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "react-bootstrap";
import { ImageModal } from "../components/ImageModal";

function importAll(r: __WebpackModuleApi.RequireContext) {
  return r.keys().map(r);
}

const requireFullImage = require.context(
  "../images/impressions",
  true,
  /\.(jpg)$/
);

const requireThumbnailImage = require.context(
  "../images/impressions",
  true,
  /\.jpg\.png$/
);

importAll(requireFullImage);
importAll(requireThumbnailImage);

interface Gallery {
  title: string;
  pictures: Picture[];
}

interface Picture {
  pathFull: string;
  pathThumnail: string;
}

function buildGalleries(files: string[]): Gallery[] {
  const galleries: Map<string, Gallery> = new Map();

  for (const file of files) {
    const galleryTitle = file.substring(2).split("/")[0];
    const existing = galleries.get(galleryTitle);

    if (existing) {
      existing.pictures.push({
        pathFull: requireFullImage(file),
        pathThumnail: requireThumbnailImage(file + ".png"),
      });
    } else {
      galleries.set(galleryTitle, {
        title: galleryTitle,
        pictures: [
          {
            pathFull: requireFullImage(file),
            pathThumnail: requireThumbnailImage(file + ".png"),
          },
        ],
      });
    }
  }

  return Array.from(galleries.values());
}

const galleries = buildGalleries(requireFullImage.keys());

interface GalleryProps {
  gallery: Gallery;
  onClick: (src: string) => void;
}

function Gallery(props: GalleryProps) {
  return (
    <>
      <h2 className="mb-2">{props.gallery.title}</h2>
      <div className="mb-4 gap-3" style={{ columns: 3 }}>
        {props.gallery.pictures.map((img, index) => (
          <a
            key={img.pathFull}
            href="#"
            onClick={(event) => {
              event.preventDefault();
              props.onClick(img.pathFull);
            }}
          >
            <img
              loading="lazy"
              width="100%"
              src={img.pathThumnail}
              className="shadow-sm rounded mb-3"
              alt={`${props.gallery.title}/${index + 1}`}
            />
          </a>
        ))}
      </div>
    </>
  );
}

const Impressions = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [currentImageSrc, setCurrentImageSrc] = React.useState<null | string>(
    null
  );

  return (
    <Container fluid="md" className="content-center about-width">
      <Row className="mt-3 spacerBottom-1 bd-bottom-2">
        <Col className="content-title shadow-title ml-3 main-title">
          <ImageModal
            onHide={() => {
              setShowModal(false);
            }}
            src={currentImageSrc ?? ""}
            show={showModal}
          />
          <FormattedMessage id="impression.header" />
          {Array.from(galleries.values()).map((g) => (
            <Gallery
              key={g.title}
              gallery={g}
              onClick={(src) => {
                setShowModal(true);
                setCurrentImageSrc(src);
              }}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Impressions;
